.map-container {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}
button {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}
button:hover {
  background-color: #0056b3;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.contact-page {
  background-image: url("../../assets/breadcrumb.jpg");
  background-size: cover;
  background-position: center;
  min-height: 50vh;
  position: relative;
}

.contact-head {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-headSpan {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.contact-headSpan::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, blue);
  opacity: 1;
  animation: slide-border 2s infinite alternate;
}
@media (max-width: 768px) {
  .contact-page {
    min-height: 40vh;
  }
  .image-right {
    position: absolute;
    top: 12px;
    right: 40px;
    animation: zoomEffect 1.3s infinite alternate;
  }
}

.image-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px;
  height: 300px;
  overflow: hidden;
}

.moving-image {
  width: 100%;
  height: 100%;
  transform: scale(1); /* Initial scale */
  transition: transform 0.5s ease; /* Smooth zoom effect */
}

.moving-image:hover {
  transform: scale(1.2); /* Zoom in on hover */
}

.image-right {
  position: absolute;
  bottom: 0px; /* Moved up by 20px */
  right: 40px; /* Moved left by 20px */
  animation: zoomEffect 1.3s infinite alternate; /* Infinite zoom in/out */
}

@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}
