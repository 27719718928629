.about-section {
  padding: 100px 0px 50px 0px;
}

.about-heading {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.about-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, blue);
  opacity: 1;
  animation: slide-border 2s infinite alternate;
}

@keyframes slide-border {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

.about-image {
  width: 90%;
  transition: transform 0.5s ease, border-radius 0.5s ease;
}

.image-wrapper {
  overflow: hidden; /* Prevent image from growing outside */
}

.image-wrapper:hover .about-image {
  transform: scale(1.1) rotate(1deg); /* Zoom in and rotate */
  filter: brightness(0.8); /* Darken the image */
  border-radius: 10px;
  transition: transform 0.5s ease, filter 0.5s ease;
}

.custom-border {
  border: 4px solid #007bffad;
  border-radius: 8px;
  width: calc(100% + -60px) !important; /* Adjust based on padding */
  height: calc(100% + -60px) !important; /* Adjust based on padding */
  top: -10px !important; /* Position the border */
  left: -10px !important; /* Position the border */
  z-index: -1; /* Place it behind the image */
}

@media (max-width: 768px) {
  .about-content {
    text-align: center;
    margin-top: 30px !important;
  }
  .custom-border {
    top: 0px !important;
    left: 0px !important;
  }
  .about-page {
    height: 40vh !important;
  }
  .image-right {
    position: absolute;
    top: 12px;
    right: 40px;
    animation: zoomEffect 1.3s infinite alternate;
  }
  .about-image {
    width: 100%;
    height: auto;
  }
}

.stat-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}
.about-page {
  height: 50vh;
  background-image: url("../../assets/breadcrumb.jpg");
  position: relative;
}

.service-heading {
  color: #333; /* Adjust color as needed */
}

.image-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px; /* Increased width */
  height: 300px; /* Increased height */
  overflow: hidden;
}

.moving-image {
  width: 100%;
  height: 100%;
  transform: scale(1); /* Initial scale */
  transition: transform 0.5s ease; /* Smooth zoom effect */
}

.moving-image:hover {
  transform: scale(1.2); /* Zoom in on hover */
}

.image-right {
  position: absolute;
  bottom: 0px; /* Moved up by 20px */
  right: 40px; /* Moved left by 20px */
  animation: zoomEffect 1.3s infinite alternate; /* Infinite zoom in/out */
}

@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.custom-card {
  text-align: center;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Higher cards on large screens only */
@media (min-width: 768px) {
  .higher-card {
    margin-top: -20px; /* Higher position on large screens */
  }
  .lower-card {
    margin-top: 30px; /* Lower position on large screens */
  }
}

/* On mobile screens, no staggered effect */
@media (max-width: 767px) {
  .higher-card,
  .lower-card {
    margin-top: 0; /* No margin-top adjustments for mobile */
  }
}

.card-icon {
  color: #6a5acd; /* Icon color */
  margin-bottom: 15px;
}

.card-text {
  color: #6c757d; /* Subtle text color */
}

.about-section {
  background-color: #f8f9fa; /* light background for the section */
  padding: 50px 0;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
}

.stat-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.image-body {
  background-color: white;
  border-radius: 50%;
  margin-top: 10px;
}
.stat-card img {
  padding: 20px;
  width: 100px; /* equal width for all images */
  height: 100px; /* equal height for all images */
  object-fit: contain; /* ensure image scales properly */
}

.stat-card h2 {
  font-size: 2.5rem; /* increase font size for numbers */
  font-weight: bold;
  margin: 0;
}

.stat-card p {
  font-size: 1rem;
  margin-top: 10px;
}

@media (min-width: 768px) {
  .stat-card h2 {
    font-size: 3rem; /* even larger for bigger screens */
  }
}
