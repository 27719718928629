.service-page {
  background-image: url("../../assets/breadcrumb.jpg");
  background-size: cover;
  background-position: center;
  min-height: 50vh;
  position: relative;
}

.default-bg {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
}

.lite-gray-bg {
  background-color: #fafafa;
  padding: 20px;
  border-radius: 8px;
}

.image-head {
  color: #3398ea;
  font-weight: 500;
  text-align: justify;
  font-size: 28px;
}

.service-image {
  width: 100%;
  height: 330px;
  transition: transform 0.5s ease, filter 0.5s ease, border-radius 0.5s ease;
}

.service-image-container {
  overflow: hidden;
}

.service-image-container:hover .service-image {
  transform: scale(1.1) rotate(3deg);
  filter: brightness(0.8);
  border-radius: 10px;
}

.service-card-content-p {
  font-size: 20px;
  font-weight: 600;
}

.servicecard-heading {
  color: black;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
}

@media (max-width: 768px) {
  .service-page {
    min-height: 40vh;
  }
  .image-right {
    position: absolute;
    top: 12px;
    right: 40px;
    animation: zoomEffect 1.3s infinite alternate;
  }
}
.servicecard-span {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.servicecard-span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, blue);
  opacity: 1;
  animation: slide-border 2s infinite alternate;
}

.service-points {
  color: #777777;
  line-height: 30px;
}

.image-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 300px; /* Increased width */
  height: 300px; /* Increased height */
  overflow: hidden;
}

.moving-image {
  width: 100%;
  height: 100%;
  transform: scale(1); /* Initial scale */
  transition: transform 0.5s ease; /* Smooth zoom effect */
}

.moving-image:hover {
  transform: scale(1.2); /* Zoom in on hover */
}

.image-right {
  position: absolute;
  bottom: 0px; /* Moved up by 20px */
  right: 40px; /* Moved left by 20px */
  animation: zoomEffect 1.3s infinite alternate; /* Infinite zoom in/out */
}

@keyframes zoomEffect {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.service-scroll-container {
    overflow-x: auto;
    overflow-y: hidden; /* Hides the vertical scrollbar */
    scrollbar-width: none; /* Firefox */
  }
  
  .service-scroll-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Opera */
  }