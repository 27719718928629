.testimonial-conatiner {
  padding: 50px 0px 50px 0px;
  background-color: #fafafa;
}

.testimonial-heading {
  font-weight: 600;
  line-height: 1.4;
  margin-bottom: 20px;
  font-size: 28px;
}

@media (min-width: 768px) {
  .testimonial-heading-dark {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .testimonial-heading-dark {
    font-size: 48px;
  }
}

@media (min-width: 768px) {
  .testimonial-heading {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .testimonial-heading {
    font-size: 48px;
  }
}
.testimonial-para {
  font-size: 18px;
}
.testimonial-para-dark {
  font-size: 18px;
  color: #d9dbdf;
}
.rounded-icon {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.carsusel-card {
  border: none;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  min-height: 280px;
}
.carsusel-card:hover {
  transform: scale(1.03);
}
.testimonial-text {
  font-size: 1.1rem;
  color: rgb(151, 151, 151);
  font-weight: 300;
  margin-top: 10px;
  line-height: 1.6;
  font-weight: 400;
  font-style: italic;
}

.testimonial-author {
  font-size: 1.3rem;
  font-weight: bold;
  /* color: #ff5b5b; */
  margin-top: 20px;
}

.testimonial-stars {
  font-size: 20px;
  color: #ffc107; /* Custom yellow color for stars */
}
@media (max-width: 768px) {
  .carsusel-card {
    text-align: center;
  }
}
.Testi-heading {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.Testi-heading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, blue);
  opacity: 1;
  animation: slide-border 2s infinite alternate;
}
