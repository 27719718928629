.project-container {
  padding: 40px 0px 40px 0px;
}
.project-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.project-heading {
  color: black;
  font-weight: 600;
  font-size: 28px;
}

@media (min-width: 768px) {
  .project-heading {
    font-size: 36px;
  }
}

@media (min-width: 1200px) {
  .project-heading {
    font-size: 42px;
  }
}
.project-para {
  color: black;
  text-align: center;
}

/* Button Styling */
.project-button {
  border: 1px solid #fbfbfb !important;
  font-weight: bold !important;
  background-color: #222831;
  padding: 4px 20px !important;
  border-radius: 10px !important;
  transition: all 0.3s ease !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.project-button:hover {
  background-color: #ff5722 !important;
  border: none !important;
}

.project-button .arrow {
  display: inline-block;
  transition: transform 0.3s ease;
}

.project-button::after {
  content: "→";
  position: relative;
  transition: transform 0.3s ease, content 0.3s ease;
  font-size: 1.2rem;
  margin-left: 10px;
}

.project-button:hover::after {
  content: "↓";
  transform: translateY(5px);
}

.project-button:hover span {
  transform: translateY(-5px);
}

.project-card-items {
  border: none;
}

.project-title {
  color: black;
  font-size: 26px;
}

.project-image {
  width: 100%;
  height: 270px;
  border-radius: 10px;
  transition: transform 0.5s ease, border-radius 0.5s ease;
}

.image-wrapper {
  overflow: hidden; /* Prevent image from growing outside */
}

.image-wrapper:hover .project-image {
  transform: scale(1.1) rotate(1deg); /* Zoom in and rotate */
  filter: brightness(0.8); /* Darken the image */
  border-radius: 10px;
  transition: transform 0.5s ease, filter 0.5s ease;
}

.service-span {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.service-span::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, blue);
  opacity: 1;
  animation: slide-border 2s infinite alternate;
}
/* view Liink */
.view-cast-link {
  color: #bebcbc;
  font-weight: bold;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 14px;
}

.view-cast-link::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: #858484;
  position: absolute;
  bottom: -3px;
  left: 0;
  transition: width 0.3s ease;
}

.view-cast-link:hover::after {
  width: 0;
}

.view-cast-link .icon {
  margin-left: 3px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.view-cast-link:hover {
  color: #3398ea !important;
}

.view-cast-link:hover .icon {
  color: #3398ea;
  transform: translateX(5px);
}
