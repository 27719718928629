.footer-links {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links:hover {
  color: #fff;
}

.footer-icons {
  margin-right: 0.5rem;
}

.social-icons {
  display: flex;
  margin-top: 1rem;
}

.social-icons a {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0.5rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #fff;
}

@media (max-width: 768px) {
  .social-icons {
    justify-content: flex-start;
  }
}
