.background-image {
  background-image: url("../../assets//homeMain.png");
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  position: relative;
  overflow: hidden;
}

.image-gallery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section {
  padding: 60px 0px;
}

.badge {
  background-color: #ff6b6b;
  color: #fff;
  padding: 0.5rem 1rem;
  display: inline-block;
  border-radius: 30px;
  margin-bottom: 1rem;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallery-image:hover {
  transform: scale(1.05);
}

.custom-sign {
  position: relative;
  padding-bottom: 5px;
  overflow: hidden;
}

.custom-sign::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: linear-gradient(to right, red, blue);
  opacity: 1;
  animation: slide-border 2s infinite alternate;
}

@keyframes slide-border {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}

/* Styles for the animated images */
.side-image {
  width: 150px;
  height: auto;
}

.animated-left-image {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.animated-right-image {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Water drop animation container */
.water-drops {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
}

/* Each individual water drop */
.water-drop {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  opacity: 0.8;
  z-index: 1;
}

/* Individual water drop colors and animations */
.drop-1 {
  top: 50%;
  left: 10%;
  background-color: rgba(0, 136, 255, 0.5);
  animation: move-drop-1 6s infinite ease-in-out;
}

.drop-2 {
  top: 10%;
  left: 70%;
  background-color: rgba(255, 0, 0, 0.5);
  animation: move-drop-2 5s infinite ease-in-out;
}

.drop-3 {
  top: 20%;
  left: 20%;
  background-color: rgba(0, 255, 0, 0.5);
  animation: move-drop-3 8s infinite ease-in-out;
}

.drop-4 {
  top: 70%;
  left: 30%;
  background-color: rgba(255, 255, 0, 0.5);
  animation: move-drop-4 10s infinite ease-in-out;
}

.drop-5 {
  top: 40%;
  left: 60%;
  background-color: rgba(255, 165, 0, 0.5);
  animation: move-drop-5 7s infinite ease-in-out;
}

.drop-6 {
  top: 30%;
  left: 80%;
  background-color: rgba(128, 0, 128, 0.5);
  animation: move-drop-6 9s infinite ease-in-out;
}

.drop-7 {
  top: 60%;
  left: 50%;
  background-color: rgba(0, 255, 255, 0.5);
  animation: move-drop-7 11s infinite ease-in-out;
}

.drop-8 {
  top: 25%;
  left: 40%;
  background-color: rgba(255, 192, 203, 0.5);
  animation: move-drop-8 12s infinite ease-in-out;
}

.drop-9 {
  top: 45%;
  left: 75%;
  background-color: rgba(0, 0, 255, 0.5);
  animation: move-drop-9 13s infinite ease-in-out;
}

.drop-10 {
  top: 65%;
  left: 25%;
  background-color: rgba(255, 105, 180, 0.5);
  animation: move-drop-10 14s infinite ease-in-out;
}

/* Media queries for mobile devices */
@media (max-width: 768px) {
  .water-drop {
    width: 30px;
    height: 30px;
  }

  .drop-1 {
    top: 40%;
    left: 5%;
  }

  .drop-2 {
    top: 45%;
    left: 65%;
  }

  .drop-3 {
    top: 75%;
    left: 10%;
  }

  .drop-4 {
    top: 60%;
    left: 25%;
  }

  .drop-5 {
    top: 35%;
    left: 55%;
  }

  .drop-6 {
    top: 20%;
    left: 75%;
  }

  .drop-7 {
    top: 50%;
    left: 40%;
  }

  .drop-8 {
    top: 20%;
    left: 35%;
  }

  .drop-9 {
    top: 40%;
    left: 70%;
  }

  .drop-10 {
    top: 55%;
    left: 15%;
  }
}

/* Keyframes for each water drop's animation (moving in different directions) */
@keyframes move-drop-1 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, -200px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-2 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-200px, 150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-3 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(150px, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-4 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-150px, 200px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/* Repeat similar patterns for the other water drops */
@keyframes move-drop-5 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(250px, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-6 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-150px, -100px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-7 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(200px, 200px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-8 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-200px, -100px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-9 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(100px, 250px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move-drop-10 {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-250px, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}
