.nav-logo {
  height: 50px;
  min-width: auto;
}
.navbar {
  padding: 10px 0px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px 0px;
  width: 100%;
  opacity: 1;
}

.navbar-collapse-dark .navbar-collapse {
  background-color: rgb(10, 9, 9);
}
.navbar-brand {
  display: flex;
}

.LogoHead {
  color: #3398ea;
  line-height: inherit;
  font-weight: bold;
  margin-bottom: 0px !important;
}

.nav-link-dark {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  padding-bottom: 5px;
  color: rgb(255, 255, 255) !important;
  transition: color 0.3s ease;
  text-decoration: none;
}

/* For mobile view */
@media (max-width: 991px) {
  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;

    padding: 20px;
    width: 100%;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.05) 0px 4px 10px 0px;
  }
  .navbar-nav {
    margin-left: 0px !important;
  }
}

/* Nav Links */
.nav-link {
  font-weight: 600;
  font-size: 16px;
  position: relative;
  padding-bottom: 5px;
  color: rgb(37, 37, 37);
  transition: color 0.3s ease;
  text-decoration: none;
}

.nav-link:hover {
  color: #0084ff;
}
.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, red, blue);
  transition: width 0.4s ease; /* Transition for the border */
}
.nav-link:hover::after {
  width: 100%; /* Full width on hover */
}
